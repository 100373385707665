.category-product-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem;
  justify-content: center;
}

.product-container {
  max-width: 1200px;
  margin: 50px auto 0 auto;
  display: flex;
  flex-direction: row;
  font-family: Arial;
  line-height: 1.3;
  font-size: 16px;
  align-items: flex-start;
}

.image-container {
  max-width: 360px;
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.image-container .product-icons {
  max-width: 320px;
  flex: 1;
  padding: 0px 10px;
  margin: auto;
}

.image-container .product-icons .product-row {
  padding: 0;
  cursor: pointer;
  color: "var(--tertiary-colour)";
}

.image-container .product-icons .product-row img {
  display: block;
  height: 52px;
}

.image-container .product-view {
  flex: 5;
  background-color: #F8F8F8;
  align-items: center;
  margin-bottom: inherit;
  position: relative;
}

.image-container .product-view div {
  width: 336px;
  height: 225px;
  border: none;
  overflow: hidden;
}

.image-container .product-view div div video {
  object-fit: contain;
}

.image-container .product-view div figure div img {
  width: 100%;
  height: 100%;
  object-fit:contain;
}

.product-info {
  flex: 0 0 100%;
  padding-top: 30px;
}

@media only screen and (max-width: 900px) {
  .product-container {
    flex-direction: column;
  }
  .product-info {
    display: flex;
    justify-content: center;
  }
  .image-container {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .image-container .product-view div img {
    display: block;
    max-width: 293px;
    max-height: 165px;
    width: auto;
    height: auto;
    margin: 0 auto;
  }
  .image-container .product-icons {
    width: 90%;
  }
  .image-container .product-icons .product-row {
    margin-bottom: 0;
  }
  .image-container .product-icons .product-row img {
    height: 52px;
  }
}
