@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

:root {
  --primary-colour: #F8F8F8;
  --secondary-colour: #3E3E3E;
  --tertiary-colour: #FF9900;
  --light-text: #FFFFFF;
  --dark-text: var(--secondary-colour);
  --danger: #FD0000;
}

body {
  background-color: #5a5a5a;
}

::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-thumb {
  background-clip: content-box;
  background-color: gray;
  border: 4px solid transparent;
  border-radius: 8px;
}


.product-scroller-container {
  display: flex;
  width: 85%;
  margin: auto;
  margin-top: 25px;
  justify-content: center;
}

.description-text {
  color: var(--light-text);
}

.title-text {
  margin-bottom: 20%;
  color: var(--light-text);
}

.header-scroll-buttons {
  background: #3E3E3E;
  border: none;
  border-radius: 5px;
}

.subcat-scroll-buttons {
  background: #F8F8F8;
  border: none;
  border-radius: 5px;
}

.individual-product {
  border-radius: 5%;
  width: 200px;
  align-items: center;
  justify-content: center;
  background-color: #ecf0f1;
  margin: 10px;
  display: inline-block;
  transition: width 0.2s;
}

.product-info {
  align-items: 'center';
  justify-content: 'center';
  margin: 10px;
}

.category-scroller-header {
  display: flex;
  margin: 0 10px;
}

.category-box-buttons {
  background: #F8F8F8;
  border: none;
  position: absolute;
  z-index: 1000;
}

#cat-button-left {
  left: 50px;
  top: 350px;
}

#cat-button-right {
  right: 50px;
  top: 350px;
}

.line {
  margin-left: 100px;
  margin-right: 100px;
}

.shipping-form {
  margin: 15px;
  margin-left: 8%;
  width: 85%;
  height: auto;
}

.loader {
  margin: auto;
  justify-content: center;
  align-items: center;
  padding-top: 25%;
}

.loader-overlay {
  margin: auto;
  justify-content: center;
  align-items: center;
  padding-top: 25%;
  position: absolute;
  top: 0;
  left: 50%;
}

.customer-cart {
  margin: 15px;
  margin-left: 8%;
  width: 65%;
  height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.subtotal {
  margin: 15px;
  margin-right: 8%;
  width: 35%;
  height: auto;
}

.cart-subtotal {
  display: flex;
  flex-direction: row;
  height: 70%;
}

.small-button {
  border-radius: 10px;
  background-color: blue;
  color: var(--light-text);
  width: 50%;
  margin: auto;
  text-align: center;
}

.category-boxes-container {
  width: 90%;
  margin: auto;
}

#category-page-product-container {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin: auto;
  justify-content: center;
}

#left-product-scroll-button {
  transform: translateX(5px);
}

#right-product-scroll-button {
  transform: translateX(-5px);
}

.row-of-subs-container {
  width: 80%;
  margin: auto;
  display: flex;
}

.row-of-subs {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: hidden;
  transform: translateY(30px);
  -webkit-overflow-scrolling: touch;
}

.row-of-category-products {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.individual-sub-cat-wrapper {
  height: 200px;
  width: 200px;
  flex: 0 0 auto;
}

.individual-sub-cat-button {
  height: 120px;
  width: 120px;
  border-radius: 100px;
  border: none;
  align-items: center;
  justify-content: center;
  background-color: #ecf0f1;
}