#nav-links {
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

#nav-links>* {
  align-self: center;
}

#optional-nav-links {
  width: 80%;
  height: 4rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

#optional-nav-links>* {
  height: 100%;
}