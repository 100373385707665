.bennerBox {
    display: flex;
    justify-content: center;
    background-color: #FBA352;
}
.benner {
    width: 50vw;
    height: auto;
}
.iconBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    margin: 1.5rem 0;
    margin-bottom: 2rem;
}
.icon {
    display: flex;
    align-items: center;
    font-weight: 600;
    color: var(--secondary-colour);
}
.heroBox,
.midPosterBox {
    display: flex;
    justify-content: space-between;
    margin: 1rem 3rem;
}
.heroPoster {
    width: 35vw;
    height: auto;
}
.midPoster {
    width: 23vw;
    height: auto;
}
.cardBox {
    background-color: #B25520;
    width: min-content;
    border-radius: 15px;
    padding-bottom: .6rem;
    text-decoration: none;
}
.cards {
    max-width: 17vw;
    height: auto;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}
.options {
    text-align: center;
    margin: 0;
    color: white;
    font-weight: 600;
    margin-top: 0.25rem;
    font-size: .8rem;
}
.cardGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-items: center;
    gap: 1rem;
    margin: 1rem 3rem;
}
.dealsImg {
    width: 13vw;
    height: auto;
}
.deals {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin: 0 auto;
}
.dealsBox {
    text-align: center;
    font-weight: 600;
    font-size: 1.2rem;
    margin: 2rem 0;
    background-color: #F1F3ED;
    padding: 2rem;
    padding-top: 0.5rem;
}
.dealsBox > p {
    margin-top: 0;
}
.alice-carousel {
    text-align: -webkit-center;
}
